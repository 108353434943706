<template>
  <div class="mx-1">
    <b-card header="Report Homepage" class="my-2" v-if="hasAccessToHome">
      <b-card-text>
        <b-row>
          <generated-reports :reports-available="homeReports" />
        </b-row>
      </b-card-text>
    </b-card>
    <b-card header="Report Portafoglio" class="my-2" v-if="hasAccessToWallet">
      <b-card-text>
        <b-row>
          <generated-reports :reports-available="walletReports" />
        </b-row>
      </b-card-text>
    </b-card>
    <b-card
      header="Report Titoli"
      class="my-2"
      v-if="hasAccessToInsuranceAncillary"
    >
      <b-card-text>
        <b-row>
          <generated-reports :reports-available="insuranceAncillariesReports" />
        </b-row>
      </b-card-text>
    </b-card>
    <b-card
      header="Report Contabilità"
      class="my-2"
      v-if="hasAccessToAccounting"
    >
      <b-card-text>
        <b-row>
          <generated-reports :reports-available="accountingReports" />
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import GeneratedReports from "@/components/GeneratedReports";
import { canModule } from "@/utils/permissions";

export default {
  data() {
    return {
      walletReports: [
        "AGGRECLI",
        "MONUPROD",
        "REPONUCL",
        "POLCLIGRU",
        "PORTATTI",
        "SINPRECLI" /* "SCACLIGRU",  "RUBRICLI" */,
      ], // module.PFOLIO.REPG
      insuranceAncillariesReports: [
        "TITULTIGI",
        "REPOLEX",
        "RECATIIN",
        "REDETIIN",
        "TITINCACLI",
      ], // module.TIT.REPO
      accountingReports: [
        "INCAPAGA",
        "COMINCA",
        "INCACOMP",
        "INCACOMP2",
        "INCACLIGRU",
        "INCACOL",
        "INCARAM",
        "INCAPROD" /*  "RENPROD", "RENCOMP", "INCARAMTI" */,
      ], // module.CONT.REPO
      homeReports: ["REPAPP", "REPATT"], // module.HOME.REPG
    };
  },
  methods: {
    canModule,
  },
  components: { GeneratedReports },
  computed: {
    hasAccessToWallet() {
      return canModule("module.PFOLIO");
    },
    hasAccessToInsuranceAncillary() {
      return canModule("module.TIT");
    },
    hasAccessToAccounting() {
      return canModule("module.CONT");
    },
    hasAccessToHome() {
      return canModule("module.HOME");
    },
  },
};
</script>
