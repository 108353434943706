<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="showForm">
          <div class="flex-container" v-if="reportOptions.length">
            <div
              class="flex-item"
              v-for="(report, index) in reportOptions"
              :key="index"
            >
              <div class="mt-4 px-2">
                {{ report.text }}<br /><small>({{ report.value }})</small>
              </div>
              <b-button
                squared
                type="submit"
                variant="lisaweb"
                class="button"
                size="lg"
                :disabled="report.disabled"
                @click="onClickReport(report.value)"
              >
                Crea Report
              </b-button>
            </div>
          </div>
          <div v-else>Nessun report disponibile</div>
          <submit-report-modal
            :code="reportCode"
            :reportId="reportId"
            :beFormCreate="beCreateForm"
            :beRulesCreate="beRulesCreate"
            :allowedFormatOptions="allowed"
            @save-report="saveReport"
          >
          </submit-report-modal>
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import submitReportModal from "@/components/modals/submitReportModal";
import BaseIcon from "@/components/BaseIcon";
import FormMixin from "@/mixins/FormMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";

export default {
  mixins: [FormMixin, StorageGetterMixin],
  props: {
    reportsAvailable: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      repository: "report",
      resource: "reports",
      isLoading: false,
      showForm: true,
      report: null,
      reports: [],
      reportOptions: [],
      // reportsAvailable: ["AGGRECLI", "MONUPROD"],
      allowed: [],
      reportCode: null,
      reportId: null,
      beRulesCreate: null,
      beCreateForm: null,
    };
  },
  components: { submitReportModal, BaseIcon },
  methods: {
    getReports() {
      this.reports = this.$store.state.auth.reports.filter((e) =>
        this.reportsAvailable.includes(e.code)
      );
      this.reportOptions = this.reports.map((e) => {
        return {
          value: e.code,
          text: e.title,
          disabled: false,
        };
      });
    },
    actionButton(action = false) {
      this.reportOptions.forEach((report) => {
        report.disabled = action;
      });
    },
    openReportModal(code) {
      this.actionButton(true);
      this.fetchEditForm(this.repository, code)
        .then(() => {
          this.$set(this, "beCreateForm", this.beForm["report"]);
          this.$set(this, "beRulesCreate", this.beRules["report"]);
          this.$set(
            this,
            "allowed",
            this.beForm["report"].format.options.filter((format) =>
              this.allowed.includes(format.text)
            )
          );
          this.actionButton(false);
          console.log(code);
          this.$bvModal.show(code);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
    checkReportPermission(users) {
      const loggedUser = this.$store.state.auth.user;
      const found = users.find((user) => user.id === loggedUser.id);
      if (found) {
        if (found.pivot.allowed.length) {
          this.allowed = found.pivot.allowed;
          return true;
        }
      }
      return false;
    },
    onClickReport(value) {
      const report = this.reports.find((report) => report.code === value);
      if (this.checkReportPermission(report.users)) {
        this.reportCode = value;
        this.reportId = report.id;
        this.openReportModal(value);
      } else {
        this.$showSnackbar({
          preset: "info",
          text: `Non puoi eseguire il report ${value}`,
        });
      }
    },
    saveReport(code, form) {
      // this.update("report", code, form)
      const Repo = RepositoryFactory.get(this.repository);
      Repo.report(code, form)
        .then(() => {
          this.resetForm();
          this.$showSnackbar({
            preset: "info",
            text: `Richiesta accettata: report in elaborazione`,
          });
          this.$bvModal.hide(code);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$bvModal.hide(code);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
  },
  created() {
    /* */
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("reports"))
      resources.push(
        this.$store
          .dispatch(`${path}reports`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          this.getReports();
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.getReports();
      this.isLoading = false;
    }
    /* */
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  flex-flow: row wrap;
}
.flex-container > div {
  width: 160px;
  height: 160px;
  margin: 10px 20px;
  text-align: center;
  outline: 1px solid #aea7a2 !important;
}
.flex-item {
  position: relative;
}
.button {
  position: absolute;
  top: 120px;
  right: 10px;
  left: 10px;
}
</style>
